/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */
body {
    overflow-x: hidden;
}

.dropdown-menu{
    left: -70px
}

#page-content-wrapper {
    width: 100%;
}

@media (max-width: 768px) {
    #page-content-wrapper {
        min-width: auto;
    }

    .navbar {
        padding: 0px !important;
    }
}

@media (max-width: 550px) {
    .rdt_Pagination > span:first-child {
        display: none;
    }
    .rdt_Pagination > div:nth-child(2) {
        display: none;
    }   
    .rdt_Pagination > span:nth-child(3) {
        width: 80px;
        margin: 0px;
    }   
    .rdt_Pagination > div:last-child {
        display: flex !important;
    }   
}

